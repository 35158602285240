import React from 'react'
import PropTypes from 'prop-types'
import ButtonPromo from 'components/ButtonPromo'
import { Container, Divider } from 'styles/sharedStyle'

const FooterSignUp = ({
  headline,
  bodyCopy,
  ctaCopy,
  ctaUrl,
  villageName,
  colorBG,
  colorFont,
}) => (
  <>
    <Divider
      as="hr"
      borderColor="alabaster"
      width={[1, null, 0]}
      display={['none', 'block', 'none']}
    />
    <Container
      as="section"
      pt={5}
      borderColor="alabaster"
      color={colorFont}
      borderWidth={['0', '1px 0 0 0', '0']}
      maxWidth={['none', 'none', '380px']}>
      <ButtonPromo
        headline={headline}
        bodyCopy={bodyCopy}
        ctaCopy={ctaCopy}
        ctaUrl={ctaUrl}
        background={colorBG || 'White'}
        colorFont={colorFont}
        inFooter
        italicHeadline
        villageName={villageName}
      />
    </Container>
  </>
)

FooterSignUp.propTypes = {
  headline: PropTypes.string,
  bodyCopy: PropTypes.string,
  ctaCopy: PropTypes.string,
  ctaUrl: PropTypes.string,
  villageName: PropTypes.string,
  colorBG: PropTypes.string,
  colorFont: PropTypes.string,
}

FooterSignUp.defaultProps = {
  headline: '',
  bodyCopy: '',
  ctaCopy: '',
  ctaUrl: '',
  villageName: '',
  colorBG: '',
  colorFont: '',
}

export default FooterSignUp
