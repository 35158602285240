import React from 'react'
import PropTypes from 'prop-types'
import colors from 'styles/colors'
import { Divider, Container } from 'styles/sharedStyle'
import { globalHistory as history } from '@reach/router'
import FooterNav from './FooterNav'
import FooterCopyright from './FooterCopyright'
import FooterSocial from './FooterSocial'
import FooterSignUp from './FooterSignUp'
import FooterIcp from './FooterIcp'
import { FooterContainer, FooterFlex } from './Footer.style'

const Footer = ({
  copyright,
  trademarkCopy,
  navItems,
  logo,
  locale,
  reduced,
  socialItems,
  villageName,
  villageSlug,
  valueRetailPage,
  buttonPromo,
  pathOrigin,
  backgroundColor,
}) => {
  const navExtraProps = {
    locale,
    villageName,
    villageSlug,
    valueRetailPage,
  }
  const colorFont = backgroundColor ? colors.bpvSandstone : undefined
  const {
    location: { pathname },
  } = history

  const hideButtonPromo =
    pathname.includes(buttonPromo.ctaUrl) &&
    villageName === 'Belmont Park Village'
  return (
    <FooterContainer backgroundColor={backgroundColor} as="footer">
      <Container pt={[1, 10, 12]} px={[0, null, 5]}>
        {reduced && (
          <FooterFlex as="section">
            <FooterCopyright
              copyright={copyright}
              trademarkCopy={trademarkCopy}
              logo={logo}
              locale={locale}
            />
          </FooterFlex>
        )}
        {!reduced && (
          <>
            <FooterFlex
              as="section"
              flexDirection={['column', 'row']}
              flexWrap={['nowrap', 'wrap', 'nowrap']}
              overflow="hidden">
              <FooterNav
                navItems={navItems}
                navExtraProps={navExtraProps}
                colorBG={backgroundColor}
                colorFont={colorFont}
              />
              {hideButtonPromo
                ? null
                : buttonPromo && (
                    <FooterSignUp
                      {...buttonPromo}
                      colorBG={backgroundColor}
                      colorFont={colorFont}
                    />
                  )}
            </FooterFlex>

            <Divider as="hr" borderColor="alabaster" />

            <FooterFlex
              as="section"
              flexDirection={['column-reverse', null, 'row']}
              justifyContent="space-between">
              <FooterCopyright
                copyright={copyright}
                trademarkCopy={trademarkCopy}
                logo={logo}
                locale={locale}
                colorText={colorFont}
              />

              <Divider
                as="hr"
                borderColor="alabaster"
                width={[1, null, 0]}
                display={['block', null, 'none']}
              />

              <FooterSocial socialItems={socialItems} />
            </FooterFlex>
          </>
        )}
        <FooterFlex as="section" flexDirection={['column']} id="icp-footer">
          <Divider as="hr" borderColor="alabaster" />
          <FooterIcp pathOrigin={pathOrigin} />
        </FooterFlex>
      </Container>
    </FooterContainer>
  )
}

Footer.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  trademarkCopy: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  reduced: PropTypes.bool,
  socialItems: PropTypes.objectOf(PropTypes.string),
  villageName: PropTypes.string,
  villageSlug: PropTypes.string,
  pathOrigin: PropTypes.string,
  valueRetailPage: PropTypes.bool,
  buttonPromo: PropTypes.objectOf(PropTypes.string),
  backgroundColor: PropTypes.string,
}

Footer.defaultProps = {
  reduced: false,
  socialItems: null,
  villageName: '',
  villageSlug: '',
  valueRetailPage: false,
  buttonPromo: null,
  pathOrigin: '',
  backgroundColor: null,
}

export default Footer
