import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'rebass'

import { Body14 } from 'components/Typography'
import Link from 'components/Link'
import { LogoWrapper } from '../Footer.style'

const FooterCopyright = ({
  copyright,
  trademarkCopy,
  logo,
  bgcolors,
  colorText,
}) => {
  const year = new Date().getFullYear()
  return (
    <Flex
      flexDirection={['column', null, 'row']}
      flexWrap={['wrap', 'no-wrap']}
      justifyContent={['flex-start', 'center', 'flex-start']}
      alignItems={[null, 'center']}
      as="section"
      px={[4, null, 0]}
      width={['100%', null, 'auto']}
      color={colorText}
      backgroundColor={bgcolors}
      py={7}>
      <Link to="https://www.thebicestercollection.com">
        <LogoWrapper
          dangerouslySetInnerHTML={{ __html: logo }}
          mb={[1, null, 0]}
          width="187px"
          color={colorText !== '' ? colorText : 'sagedark'}
        />
      </Link>

      <Box width={[1, 'auto']}>
        <Body14
          width={1}
          fontSize={2}
          color={colorText !== '' ? colorText : 'coallight'}
          mb={[3, 0]}
          letterSpacing="normal">
          {trademarkCopy}
        </Body14>
        <Body14
          width={1}
          fontSize={2}
          lineHeight="1.8"
          color={colorText !== '' ? colorText : 'coallight'}
          mb={0}
          letterSpacing="normal">
          {copyright} {year}
        </Body14>
      </Box>
    </Flex>
  )
}

FooterCopyright.propTypes = {
  trademarkCopy: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  bgcolors: PropTypes.string,
  colorText: PropTypes.string,
  // locale: PropTypes.string.isRequired,
}

FooterCopyright.defaultProps = {
  bgcolors: '',
  colorText: '',
}

export default FooterCopyright
