import styled from 'styled-components'
import { space, width, height, themeGet } from 'styled-system'
import { transitions } from 'polished'

import theme from 'styles/theme'

const baseButtonProps = `
  display: inline-block;
  padding: ${theme.space[2]} ${theme.space[10]};
  box-shadow: 0;

  > * {
    justify-content: center;
  }
`

export const PrimaryWrapper = styled.button`
  ${baseButtonProps}
  background: ${(props) =>
    props.isActive ? themeGet('colors.alabaster') : theme.colors.white};
  border: 1px solid ${themeGet('colors.sagedark')};
  color: ${themeGet('colors.sagedark')};
  ${transitions('background ease-in 0.2s', 'color ease-in 0.2s')};
  ${(props) => props.fixedSize && `height: 60px;`}
  ${space}
  ${width}
  ${height}
  
  ${({ ctaStyle }) => ctaStyle}

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${themeGet('colors.sagedark')};
    color: ${theme.colors.white};
  }

  &:disabled {
    color: ${themeGet('colors.coallight')};
  }
`

export const SecondaryWrapper = styled.button`
  ${baseButtonProps}
  background: ${(props) =>
    props.isActive
      ? themeGet('colors.alabaster')
      : themeGet('colors.sagedark')};
  border: 1px solid ${(props) =>
    props.isActive
      ? themeGet('colors.alabaster')
      : themeGet('colors.sagedark')};
  color: ${theme.colors.white};
  ${transitions('background ease-in 0.2s', 'color ease-in 0.2s')};
  ${(props) => props.fixedSize && `height: 60px;`}
  ${space}
  ${width}
  ${height}

  ${({ ctaStyle }) => ctaStyle}

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${themeGet('colors.white')};
    border-color: ${themeGet('colors.sagedark')};
    color: ${theme.colors.sagedark};
  }

  &:disabled {
    color: ${themeGet('colors.coallight')};
  }
`

export const SecondaryBPVWrapper = styled.button`
  ${baseButtonProps}
  background: ${(props) =>
    props.isActive ? themeGet('colors.alabaster') : themeGet('colors.bpvGold')};
  border: 1px solid ${(props) =>
    props.isActive ? themeGet('colors.alabaster') : themeGet('colors.bpvGold')};
  color: ${theme.colors.white};
  ${transitions('background ease-in 0.2s', 'color ease-in 0.2s')};
  ${(props) => props.fixedSize && `height: 60px;`}
  ${space}
  ${width}
  ${height}

  ${({ ctaStyle }) => ctaStyle}

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${themeGet('colors.bpvSandstone')};
    border-color: ${themeGet('colors.sagedark')};
    color: ${theme.colors.sagedark};
  }

  &:disabled {
    color: ${themeGet('colors.coallight')};
  }
`

export const TertiaryWrapper = styled.button`
  ${baseButtonProps}
  background: ${themeGet('colors.alabaster')};
  border: 1px solid ${themeGet('colors.alabaster')};
  color: ${themeGet('colors.sagedark')};
  ${transitions('background ease-in 0.2s', 'color ease-in 0.2s')};
  ${(props) => props.fixedSize && `height: 60px;`}
  ${space}
  ${width}
  ${height}

  ${({ ctaStyle }) => ctaStyle}

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${themeGet('colors.sagedark')};
    border-color: ${themeGet('colors.sagedark')};
    color: ${theme.colors.white};
  }

  &:disabled {
    color: ${themeGet('colors.coallight')};
  }
`

export const QuaternaryWrapper = styled.button`
  ${baseButtonProps}
  background: transparent;
  border: 1px solid transparent;
  color: ${themeGet('colors.sagedark')};
  text-decoration: underline;
  ${transitions('background ease-in 0.2s', 'color ease-in 0.2s')};
  ${(props) => props.fixedSize && `height: 60px;`}
  ${space}
  ${width}
  ${height}

  ${({ ctaStyle }) => ctaStyle}

  &:disabled {
    color: ${themeGet('colors.coallight')};
  }
`
