import styled from 'styled-components'
import { display, maxHeight, themeGet } from 'styled-system'
import { transitions } from 'polished'
import { Box } from 'rebass'

import Link from 'components/Link'
import { Title26 } from 'components/Typography'
import { Block } from 'styles/sharedStyle'
import theme from 'styles/theme'

export const NavHeading = styled(Title26)`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  ${theme.mediaQueries.mobile} {
    justify-content: center;
    span {
      margin-left: 10px;
    }
  }
  ${theme.mediaQueries.small} {
    cursor: default;
  }
`

export const NavLink = styled(Link)`
  color: ${themeGet('colors.sagemedium')};

  border-bottom: 1px solid transparent;
  text-decoration: none;
  ${transitions('border-color ease-in 0.2s')};

  &:hover,
  &:focus {
    border-color: currentColor;
  }
`
export const NavLinkList = styled(Box)`
  ${maxHeight}
  ${display}
  @media screen and (max-width: ${theme.breakpoints[0]}) {
    max-height: ${(props) => (props.isOpen ? '100vh' : '0')};
    overflow: hidden;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    padding: ${(props) => (props.isOpen ? '25px 0 0' : '0')};
    transition: all 0.5s ease-out;
  }
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    max-height: 100vh;
  }


`

export const NavListItem = styled(Block)`
  ${theme.mediaQueries.small} {
    flex-grow: 1;
    flex-basis: 0;
  }
  ${theme.mediaQueries.mobile} {
    text-align: center;
  }
  ${theme.mediaQueries.medium} {
    html[dir='ltr'] & {
      margin-right: ${theme.space[3]};
    }

    html[dir='rtl'] & {
      margin-left: ${theme.space[3]};
    }
  }
`
