import React from 'react'
import PropTypes from 'prop-types'
import { Standfirst, Title60, Body16 } from 'components/Typography'
import Button from 'components/Button'
import Link from 'components/Link'
import MarkdownRenderer from 'components/MarkdownRenderer'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'
import {
  TopWhiteCurvedEdge,
  Spacing50,
  Spacing20,
  Spacing30,
} from 'styles/sharedStyle'
import { fixAudiences } from '../../utils/audiences'
import {
  ButtonPromoWrapper,
  BodyCopy,
  ButtonPromoOuter,
} from './ButtonPromo.style'

const ButtonPromo = ({
  audiences,
  headline,
  bodyCopy,
  background,
  ctaUrl,
  external,
  ctaCopy,
  inFooter,
  inPage,
  roundTopEdge,
  italicHeadline,
  villageName,
  colorFont,
}) => {
  let title = headline && (
    <>
      <Title60 color={colorFont ?? ''} as="h2">
        {headline}
      </Title60>
      <Spacing30 />
    </>
  )
  if (headline && italicHeadline)
    title = (
      <>
        <Standfirst color={colorFont ?? ''} as="h2">
          {headline}
        </Standfirst>
        <Spacing20 />
      </>
    )
  const hasAudiences = !!audiences
  let bpvButton

  if (villageName === 'Belmont Park Village') {
    bpvButton = 'secondaryBPV'
  } else if (inPage) {
    bpvButton = 'primary'
  } else {
    bpvButton = 'secondary'
  }

  return (
    !!headline &&
    !!ctaUrl &&
    !!ctaCopy && (
      <>
        {!inFooter && <Spacing50 />}
        <ButtonPromoOuter
          hasAudience={hasAudiences}
          className={fixAudiences(audiences)}>
          {roundTopEdge && <TopWhiteCurvedEdge background={background} />}
          <ButtonPromoWrapper
            background={background}
            inFooter={inFooter}
            colorFont={colorFont}
            inPage={inPage}>
            <div>
              {!inFooter && <Spacing50 />}
              {title}
              {bodyCopy && (
                <>
                  <Body16 as="div">
                    <BodyCopy
                      colorFont={colorFont}
                      as={MarkdownRenderer}
                      html={bodyCopy}
                    />
                  </Body16>
                  <Spacing20 />
                </>
              )}
              {ctaCopy && ctaUrl && (
                <Button
                  onClick={() => {
                    trackGTM(
                      'cta interaction',
                      'cta interaction',
                      'in page cta',
                      ctaCopy
                    )
                    if (inFooter) {
                      GA4('register', {
                        village_name: villageName,
                        component_location: 'footer',
                      })
                      GA4('footer_navigation_registration', {
                        village_name: villageName,
                      })
                    } else {
                      GA4('button_promo_CTA', {
                        village_name: villageName,
                      })
                    }
                  }}
                  as={Link}
                  level={bpvButton}
                  to={ctaUrl}
                  external={external}>
                  {ctaCopy}
                </Button>
              )}
              {!inFooter && <Spacing50 />}
            </div>
          </ButtonPromoWrapper>
        </ButtonPromoOuter>
        <Spacing50 />
      </>
    )
  )
}

ButtonPromo.propTypes = {
  audiences: PropTypes.string,
  headline: PropTypes.string,
  bodyCopy: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ctaUrl: PropTypes.string,
  external: PropTypes.bool,
  ctaCopy: PropTypes.string,
  background: PropTypes.string,
  inFooter: PropTypes.bool,
  inPage: PropTypes.bool,
  roundTopEdge: PropTypes.bool,
  italicHeadline: PropTypes.bool,
  villageName: PropTypes.string,
  colorFont: PropTypes.string,
}

ButtonPromo.defaultProps = {
  audiences: '',
  headline: '',
  bodyCopy: '',
  ctaUrl: '',
  external: false,
  ctaCopy: '',
  background: 'White',
  inFooter: false,
  inPage: false,
  roundTopEdge: false,
  italicHeadline: false,
  villageName: '',
  colorFont: '',
}

export default ButtonPromo
