exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-brand-details-page-template-t-06-js": () => import("./../../../src/templates/BrandDetailsPageTemplateT06.js" /* webpackChunkName: "component---src-templates-brand-details-page-template-t-06-js" */),
  "component---src-templates-editorial-page-template-t-08-js": () => import("./../../../src/templates/EditorialPageTemplateT08.js" /* webpackChunkName: "component---src-templates-editorial-page-template-t-08-js" */),
  "component---src-templates-generic-page-template-t-13-js": () => import("./../../../src/templates/GenericPageTemplateT13.js" /* webpackChunkName: "component---src-templates-generic-page-template-t-13-js" */),
  "component---src-templates-home-page-template-t-01-js": () => import("./../../../src/templates/HomePageTemplateT01.js" /* webpackChunkName: "component---src-templates-home-page-template-t-01-js" */),
  "component---src-templates-new-village-registration-page-t-10-js": () => import("./../../../src/templates/NewVillageRegistrationPageT10.js" /* webpackChunkName: "component---src-templates-new-village-registration-page-t-10-js" */),
  "component---src-templates-section-detail-page-template-t-03-js": () => import("./../../../src/templates/SectionDetailPageTemplateT03.js" /* webpackChunkName: "component---src-templates-section-detail-page-template-t-03-js" */),
  "component---src-templates-section-landing-page-template-t-02-js": () => import("./../../../src/templates/SectionLandingPageTemplateT02.js" /* webpackChunkName: "component---src-templates-section-landing-page-template-t-02-js" */),
  "component---src-templates-village-opening-hours-page-js": () => import("./../../../src/templates/VillageOpeningHoursPage.js" /* webpackChunkName: "component---src-templates-village-opening-hours-page-js" */)
}

