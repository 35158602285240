import React from 'react'
import PropTypes from 'prop-types'
import { Flex as NavList } from 'rebass'

import FooterNavListItem from './FooterNavListItem'

const FooterNav = ({ navItems, navExtraProps, colorFont }) => {
  // ## FORCING AN EMPTY EVIDON CONSENT LINK ONTO THE END OF THE LIST
  // ## THIS IS THEN PICKED UP BY THE COOKIE CONSENT JS
  const finalColumnLinksLength = navItems[navItems.length - 1].links.length
  // eslint-disable-next-line no-param-reassign
  navItems[navItems.length - 1].links[finalColumnLinksLength] = {
    label: '',
    link: '',
    external: false,
    customClassName: 'evidon-notice-link',
  }

  return (
    <NavList
      as="ul"
      flexDirection={['column', 'row']}
      justifyContent="space-around"
      width={[1, 1, '70%']}
      m={[0]}
      p={[0]}
      pb={[0, 9, 9]}
      style={{ listStyle: 'none' }}>
      {navItems &&
        navItems.map((item) => (
          <FooterNavListItem
            key={item.category}
            {...item}
            {...navExtraProps}
            colorFont={colorFont}
          />
        ))}
    </NavList>
  )
}

FooterNav.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  navExtraProps: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    villageSlug: PropTypes.string,
    valueRetailPage: PropTypes.bool,
  }).isRequired,
  colorFont: PropTypes.string,
}

FooterNav.defaultProps = {
  colorFont: '',
}

export default FooterNav
