export function urlJoiner(paths) {
  return ['', ...paths].join('/').replace(/\/+/g, '/')
}

export function constructUrlPath({
  nodeLocale,
  pageLevel,
  translatedServices,
  translatedSlugs,
  url,
  villageSlug,
}) {
  if (!url) {
    return false
  }
  if (url && (url.indexOf('http:') === 0 || url.indexOf('https:') === 0)) {
    return url
  }
  let translatedService = null
  if (translatedServices) {
    translatedService =
      pageLevel === 'services' ? translatedServices[nodeLocale] : 'bookings'
  }
  // For services home page translatedSlug is 'services' translation
  const translatedSlug = translatedSlugs && translatedSlugs[nodeLocale]

  // remove all double slashes from the url
  return urlJoiner([
    (pageLevel === 'value-retail' && 'value-retail') || villageSlug || '',
    nodeLocale,
    pageLevel === 'services' ? translatedService || translatedSlug : '',
    url,
  ])
}

export function normalizeInternalUrl(url) {
  // if invalid url return and empty string to not break the page...
  if (url.length < 2) return ''

  // regex for external URL, does not modify it
  if (/^http(s)*:\/\//gi.test(url)) return url

  return /^\//gi.test(url) ? url : `/${url}`
}
